/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/reset.scss';
@import 'assets/scss/global/';
@import 'assets/scss/components';

html {
  font-size: 16px;
  height: 100%;
  max-height: 100%;
}

body {
  @include text('regular');
  background-color: color(light);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

app-root {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  max-height: 100%;
  position: relative;
}

button {
  color: inherit;
}