$base-colors: (
  'text': #444444,
  'dark': #333333,
  'gray': #888888,
  'light-gray': #BBBBBB,
  'light': #EEEEEE,
  'blue': #304FFE
);

@mixin text-color($name) {
  color: map-get($base-colors, $name);
}

@function color($name) {
  @return map-get($base-colors, $name);
}