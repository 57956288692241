@import '../global/index.scss';

.admin-page {
  display: flex;
  flex-direction: column;
  padding: 0 space(large);

  &--flex-row {
    flex-direction: row;
  }

  &-header {
    display: flex;
    margin-top: space(large) !important;

    &__title {
      flex: 1 1 auto;
    }

    &__actions {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: flex-end;

      > * {
        margin-left: space(regular) !important;
      }
    }
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    &-header {
      display: flex;
      margin-top: space(large);
      margin-bottom: space(regular);

      &__title {
        flex: 1 1 auto;
      }

      &__actions {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: flex-end;

        > * {
          margin-left: space(regular) !important;
        }
      } 
    }

    &-block {
      display: block;
      margin-bottom: space(regular);
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    margin: space(regular) 0;
    justify-content: flex-end;
  
    > * {
      flex: 0 0 auto;
      margin-left: space(regular) !important;
    }
  
    &__item--pull-left {
      justify-self: flex-start;
      margin: 0 auto 0 0 !important;
    }
  }
}
