@import 'colors';

@mixin font-family-regular {
  font-family: 'Raleway', sans-serif;
}

@mixin font-family-headings {
  font-family: 'Montserrat', sans-serif;
}

$base-font-size: 16px;

$font-sizes: (
  'extra-small': 0.5rem,
  'small': 0.875rem,
  'regular': 1rem,
  'medium': 1.5rem,
  'large': 2rem,
  'huge': 3rem,
);

@mixin heading($size) {
  @include font-family-headings;
  @include text-color('text');
  font-size: map-get($font-sizes, $size);
}

@mixin text($size) {
  @include font-family-regular;
  @include text-color('text');
  font-size: map-get($font-sizes, $size);
}

p {
  @include text('regular');
}

h1 {
  @include heading('huge');
}

h2 {
  @include heading('large');
}

h3 {
  @include heading('medium');
}

h4 {
  @include heading('regular');
}
